
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function onClick2() {
                    {
                        window.Convermax.handleAddToCartButton(this.variant_ids[0]);
                    }
                }
                return _createElement('div', { 'className': 'product-card card-' + this.id }, _createElement('modal-container', {
                    'id': 'QuickViewModalId-' + this.id,
                    'remote-url': this.url + '?view=quick-view',
                    'remote-target': '#ProductQuickView',
                    'class': '#modal-container'
                }, _createElement('dialog', {
                    'data-element': 'dialog',
                    'className': '#modal-container-dialog'
                }, _createElement('div', {
                    'className': '#modal-container-content',
                    'data-element': 'content'
                }), _createElement('div', {
                    'className': '#modal-container-spinner',
                    'data-element': 'spinner'
                }, _createElement('div', { 'className': '#spinner @size:md' }, _createElement('div', {}), _createElement('div', {}), _createElement('div', {}))))), _createElement('div', { 'className': '#product-card-badges @position:left badges' }, this.on_sale ? _createElement('div', {
                    'className': '#badge @type:sale badge-sale',
                    'key': '614'
                }, '\n      ', Math.ceil((1 - this.price / this.compare_at_price) * 100), '% Off\n    ') : null), _createElement('div', { 'className': '#product-card-media product-card-media' }, _createElement('div', { 'className': '#product-card-carousel-tiles product-card-carousel-tiles' }, this.image ? _createElement('a', {
                    'tabIndex': '-1',
                    'href': this.url,
                    'className': '#product-card-carousel-tile carousel-tile',
                    'data-image-url': this.resizeImage(this.image),
                    'data-carousel-tile': '1',
                    'data-product-id': this.id,
                    'key': '909'
                }) : null, this.image2 ? _createElement('a', {
                    'tabIndex': '-1',
                    'href': this.url,
                    'className': '#product-card-carousel-tile carousel-tile',
                    'data-image-url': this.resizeImage(this.image2),
                    'data-carousel-tile': '2',
                    'data-product-id': this.id,
                    'key': '1120'
                }) : null, this.image3 ? _createElement('a', {
                    'tabIndex': '-1',
                    'href': this.url,
                    'className': '#product-card-carousel-tile carousel-tile',
                    'data-image-url': this.resizeImage(this.image3),
                    'data-carousel-tile': '3',
                    'data-product-id': this.id,
                    'key': '1333'
                }) : null, this.image4 ? _createElement('a', {
                    'tabIndex': '-1',
                    'href': this.url,
                    'className': '#product-card-carousel-tile carousel-tile',
                    'data-image-url': this.resizeImage(this.image4),
                    'data-carousel-tile': '4',
                    'data-product-id': this.id,
                    'key': '1546'
                }) : null, this.image5 ? _createElement('a', {
                    'tabIndex': '-1',
                    'href': this.url,
                    'className': '#product-card-carousel-tile carousel-tile',
                    'data-image-url': this.resizeImage(this.image5),
                    'data-carousel-tile': '5',
                    'data-product-id': this.id,
                    'key': '1759'
                }) : null), _createElement('div', { 'className': 'product-card-media-inner' }, _createElement('div', { 'className': '#media' }, _createElement('img', {
                    'className': '#media-image media-image',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })))), _createElement('div', { 'className': 'product-card-carousel-pagination' }, this.image && this.image2 ? _createElement('div', {
                    'className': 'product-card-carousel-pagination-item active',
                    'data-carousel-pagination': '1',
                    'key': '2306'
                }, _createElement('span', {})) : null, this.image2 ? _createElement('div', {
                    'className': 'product-card-carousel-pagination-item',
                    'data-carousel-pagination': '2',
                    'key': '2466'
                }, _createElement('span', {})) : null, this.image3 ? _createElement('div', {
                    'className': 'product-card-carousel-pagination-item',
                    'data-carousel-pagination': '3',
                    'key': '2597'
                }, _createElement('span', {})) : null, this.image4 ? _createElement('div', {
                    'className': 'product-card-carousel-pagination-item',
                    'data-carousel-pagination': '4',
                    'key': '2728'
                }, _createElement('span', {})) : null, this.image5 ? _createElement('div', {
                    'className': 'product-card-carousel-pagination-item',
                    'data-carousel-pagination': '5',
                    'key': '2859'
                }, _createElement('span', {})) : null), _createElement('div', { 'className': 'product-card-caption' }, _createElement('h3', { 'className': '#product-card-title product-card-title' }, _createElement('span', mergeProps({ 'className': '#text-truncate' }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': '#product-card-price product-card-price' }, _createElement('dl', { 'className': '#price price' }, _createElement('div', { 'className': '#price-item @regular' }, _createElement('dt', { 'className': '#price-item-heading visually-hidden' }, 'Price'), _createElement('dd', { 'className': '#price-item-value' }, _createElement('span', { 'className': '#price-value' }, this.formatPrice(this.price)))), _createElement('div', { 'className': '#price-item @compare price-item-compare' }, _createElement('dt', { 'className': '#price-item-heading visually-hidden' }, 'Regular price'), _createElement('dd', { 'className': '#price-item-value' }, _createElement('span', { 'className': '#price-value' }, this.formatPrice(this.compare_at_price)))))), _createElement('div', { 'className': '#product-card-quick-action product-card-button' }, _createElement('modal-open', {
                    'tabIndex': '0',
                    'target': 'QuickViewModalId-' + this.id,
                    'class': '#button @tier:secondary @display-style:solid @block product-card-button-quick-view button'
                }, '\n        Quick view\n      '), _createElement('product-to-cart', { 'variant-id': this.variant_ids[0] }, _createElement('button', {
                    'onClick': onClick2.bind(this),
                    'className': '#button @tier:secondary @display-style:solid @block button',
                    'data-element': 'to-cart-button'
                }, '\n          Add to cart\n          ', _createElement('div', { 'className': '#spinner @size:md' }, _createElement('div', {}), _createElement('div', {}), _createElement('div', {})))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []