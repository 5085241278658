
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function CustomSearchHeaderRT () {
    return !!window.document.body.classList.contains('page-collection') || window.location.pathname === '/pages/search' ? _createElement('div', {
        'className': 'clear-self cm_CustomSearchHeader',
        'key': '0'
    }, [this.SearchHeader(function () {
            return _createElement('div', { 'className': '#container @width:default cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'cm_button cm_button__primary cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                    'className': '#icon cm_icon cm_filter-icon',
                                    'key': '740'
                                }, _createElement('use', { 'href': '#svg-icon-filter' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': '#icon cm_icon cm_filter-icon',
                                    'key': '2140'
                                }, _createElement('use', { 'href': '#svg-icon-filter' }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', {
                'className': 'cm_total-hits',
                'role': 'status'
            }, _createElement('span', {}, this.totalHits, ' PRODUCTS')), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })]) : null;
}
        export const componentNames = ["cm:dialogButton","cm:facetDialogButton","cm:SearchHeader"]