import shopifyGenericDefaults from '../shopify-generic/config.js';

globalThis.Convermax.handleAddToCartButton = (id) => {
  const addButton = window.document.querySelector(`[variant-id="${id}"]`);

  if (addButton) {
    setTimeout(() => {
      addButton.querySelector('button').classList.remove('!loading');
    }, 1500);
  }
};

// handle image switcher on plp
function handleProductImageSwitcher(tile) {
  const currentProduct = window.document.querySelector(`.card-${tile.getAttribute('data-product-id')}`);
  const currentImage = currentProduct.querySelector('.media-image');
  const newImageSrc = tile.getAttribute('data-image-url');
  const allPaginationItems = currentProduct.querySelectorAll('.product-card-carousel-pagination-item');
  const currentPaginationItem = currentProduct.querySelector(
    `[data-carousel-pagination="${tile.getAttribute('data-carousel-tile')}"]`,
  );

  if (currentImage && newImageSrc && currentPaginationItem && allPaginationItems) {
    currentImage.src = newImageSrc;
    allPaginationItems.forEach((item) => item.classList.remove('active'));
    currentPaginationItem.classList.add('active');
  }
}

const defaultUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
).updateCallback;
shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchResult').updateCallback = () => {
  defaultUpdateCallback();

  const productTiles = window.document.querySelectorAll('.carousel-tile');

  if (productTiles) {
    productTiles.forEach((tile) => {
      tile.onmouseover = () => {
        handleProductImageSwitcher(tile);
      };

      tile.onmouseover = () => {
        handleProductImageSwitcher(tile);
      };
    });
  }
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['SearchHeader'].includes(w.name)),
    {
      name: 'SearchHeader',
      type: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
    },
    {
      name: 'CustomSearchHeader',
      type: 'SearchPage',
      location: { insertAfter: '.page-header' },
      template: 'CustomSearchHeader',
    },
  ],
};
